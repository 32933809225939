<template>
  <div>
    <banners-components />
    <header-site />
    <about-components />
    <publish-components />
    <solutions-components />
    <footer-site />
  </div>
</template>

<script>
export default {
  name: 'home-page',
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="sass">
  @import "../assets/sass/main.sass"
  *
    padding: 0
    margin: 0
    font-style: normal
    text-decoration: none
    box-sizing: border-box
    list-style: none
    background-repeat: no-repeat
    background-size: 100%
  input, textarea
    background-color: transparent
    border: 2px solid $corVerde
    color: #fff
    padding: 5px 10px
    box-sizing: border-box
    outline: 0
    resize: none
    font-weight: 400
  body
    font-size: 18px
    font-weight: 400
    background-color: #FFF
    padding-top: 120px
    h1
      text-align: center
      font-weight: 700
      color: $corAzul
      margin-bottom: 15px
    h2
      font-weight: 500
    h3
      text-align: center
      color: $corAzul
      font-size: 24px
      margin-bottom: 30px
    .exibeMobile
      display: none
    .ocultaMobile
      display: block
  @media all and (max-width:767px)
    body
      width: 100%
      padding-top: 70px
      &:before
        position: fixed
        z-index: 10
        top: 70px
        z-index: 100000
      .ocultaMobile
        display: none
      .exibeMobile
        display: block
        text-align: center
      .imagensDestaques
        .blocoImagem
          width: 25%
          background-size: auto 100%
          background-position: 50%
</style>
