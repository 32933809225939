import { render, staticRenderFns } from "./solutions.vue?vue&type=template&id=6f0addf7&scoped=true&"
import script from "./solutions.vue?vue&type=script&lang=js&"
export * from "./solutions.vue?vue&type=script&lang=js&"
import style0 from "./solutions.vue?vue&type=style&index=0&id=6f0addf7&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f0addf7",
  null
  
)

export default component.exports