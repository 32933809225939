<template>
  <div id="quemSomos">
    <h2>
      Quem Somos
    </h2>
    <h1>
      A Diatech atua na representação comercial, sistemas de aquecimento de água e
      climatização / renovação de ar.
    </h1>
    <p class="text-about">
      Somos uma empresa autorizada na intermediação de negócios no estado de Minas Gerais
      direto com os fabricantes Bosch Termotecnologia, Heliotek, Ventokit e WdB, Comparco,
      Actua Controls e KAO Dutos, oferece consultoria comercial e técnica nos seguimentos
      de geração de água quente, ventilação e ar condicionado.
    </p>
    <div class="englobaBlocos">
      <div id="missao">
        <h3>
          Missão
        </h3>
        <p>
          Fornecer aos nossos clientes parceiros um atendimento de excelência e uma gama
          completa de produtos e serviços no segmento de aquecimento de água,
          refrigeração, ar condicionado, ventilação e construção civil.
        </p>
      </div>
      <div id="objetivo">
        <h3>
          Objetivo
        </h3>
        <p>
          Tornar-se a representada referência no mercado de Minas Gerais nos seguimentos
          de aquecimento de água, refrigeração, ar condicionado e ventilação, atendendo
          aos seguimentos de distribuição, revenda e construção civil.
        </p>
      </div>
      <div id="valores">
        <h3>
          Valores
        </h3>
        <ul>
          <li>Parceria;</li>
          <li>Preço justo;</li>
          <li>Meio ambiente;</li>
          <li>Soluções completas;</li>
          <li>Satisfação do cliente;</li>
          <li>Atendimento personalizado;</li>
          <li>Valorização e respeito ao cliente.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about-components',
};
</script>

<style lang="sass" scoped>
  @import "../assets/sass/main.sass"
  #quemSomos
    width: 1280px
    margin: 0 auto
    padding: 0 15px
    margin-top: 60px
    h1
      text-align: left
      font-size: 18px
      color: #000
      font-weight: 400
      margin: 0 0 6px
      text-align: justify
    h2
      font-size: 36px
      width: 100%
      font-weight: 700
      text-align: center
      color: $corAzul
      font-weight: 700
      margin-bottom: 12px
    h3
      text-align: justify
      margin: 20px 0 30px
      font-size: 1em
    .text-about
      text-align: justify
    .englobaBlocos
      overflow: hidden
      display: none
      #missao, #objetivo, #valores
        width: 31%
        border: 1px solid $corVerde
        margin: 10px 0
        padding: 20px
        text-align: justify
        overflow: hidden
        float: left
  @media all and (min-width:$larguraSecundaria) and (max-width:$larguraPrimaria)
    #quemSomos
      width: $larguraSecundaria
  @media all and (max-width:767px)
    #quemSomos
      width: 100%
      .englobaBlocos
        width: 100%
        #missao, #objetivo, #valores
          width: 100%
</style>
