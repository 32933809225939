import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './routers/main';

Vue.use(VueRouter);

// Load all Components
const requireComponents = require.context('./', true, /.vue$/);
requireComponents.keys().forEach((fileName) => {
  const component = requireComponents(fileName);
  const componentName = component.default.name;
  Vue.component(componentName, component.default || component);
});

let app = '';
if (!app) {
  app = new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#webDiatech');
}

// definicao de variaveis
/* eslint-disable */
let cliqueMobile = '';
let elemento = '';
let englobaBanners = '';
let bannerFato = '';
let controleLeft = '';
let controleRight = '';
const larguraTela = window.innerWidth;
window.onload = initPage;
function initPage() {
  // Função referente ao clique no menu mobile
  cliqueMobile = document.getElementById('quadrado');
  elemento = document.getElementById('itensMenu');
  cliqueMobile.addEventListener('click', () => {
    if (elemento.style.top === '-230px' || elemento.style.top === '') {
      elemento.style.top = '68px';
    } else {
      elemento.style.top = '-230px';
    }
  });
  // Banner
  englobaBanners = document.getElementById('englobaBanners');
  bannerFato = document.getElementsByClassName('bannerFato');
  // controleRight = document.getElementById('right');
  // controleLeft = document.getElementById('left');
  let acumulador = 0;
  // controles
  // controleRight.addEventListener('click', () => {
  //   acumulador += 1;
  //   if (acumulador === 4) {
  //     acumulador = 0;
  //   }
  //   englobaBanners.style.left = `${-1 * acumulador * 100}%`;
  // });
  // controleLeft.addEventListener('click', () => {
  //   acumulador -= 1;
  //   if (acumulador === -1) {
  //     acumulador = 3;
  //   }
  //   englobaBanners.style.left = `${-1 * acumulador * 100}%`;
  // });

  const home = document.getElementById('home');
  const empresa = document.getElementById('empresa');
  const solucoes = document.getElementById('produtos');
  const contato = document.getElementById('contato');
  home.addEventListener('click', (event) => {
    closeMenu();
    event.preventDefault();
    scrollTo(document.getElementById('banner').offsetTop - 130);
  }, false);
  empresa.addEventListener('click', (event) => {
    closeMenu();
    event.preventDefault();
    scrollTo(document.getElementById('quemSomos').offsetTop - 130);
  }, false);
  solucoes.addEventListener('click', (event) => {
    closeMenu();
    event.preventDefault();
    scrollTo(document.getElementById('solucoes').offsetTop - 130);
  }, false);
  contato.addEventListener('click', (event) => {
    closeMenu();
    event.preventDefault();
    scrollTo(document.getElementById('footer').offsetTop - 130);
  }, false);

  window.scrollTo = (function () {
    let timer; let start; let
      factor;
    return function (target, duration) {
      const offset = window.pageYOffset;
      const delta = target - window.pageYOffset;
      duration = duration || 500;
      start = Date.now();
      factor = 0;

      if (timer) {
        clearInterval(timer);
      }

      function step() {
        let y;
        factor = (Date.now() - start) / duration;
        if (factor >= 1) {
          clearInterval(timer);
          factor = 1;
        }
        y = factor * delta + offset;
        window.scrollBy(0, y - window.pageYOffset);
      }
      timer = setInterval(step, 10);
      return timer;
    };
  }());
}
function closeMenu() {
  elemento = document.getElementById('itensMenu');
  elemento.style.top = '-230px';
}
/* eslint-enable */
