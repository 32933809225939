<template>
  <div class="imagensDestaques">
    <div class="blocoImagem ocultaMobile" id="imgUm" />
    <div class="blocoImagem" id="imgDois" />
    <div class="blocoImagem ocultaMobile" id="imgTres" />
    <div class="blocoImagem" id="imgQuatro" />
    <div class="blocoImagem ocultaMobile" id="imgCinco" />
    <div class="blocoImagem" id="imgSeis" />
    <div class="blocoImagem ocultaMobile" id="imgSete" />
    <div class="blocoImagem" id="imgOito" />
  </div>
</template>

<script>
export default {
  name: 'featured-image',
  components: {

  },
  beforeMount() {

  },

  data() {
    return {

    };
  },

  methods: {

  },
};
</script>

<style lang="sass" scoped>
  @import '../assets/sass/_variables.sass'
  .imagensDestaques
    width: 100%
    height: 100px
    margin: 70px 0
    .blocoImagem
      width: 12.5%
      height: 100%
      border-top: 2px solid $corVerde
      border-right: 2px solid $corVerde
      border-bottom: 2px solid $corVerde
      background-size: cover
      background-position: center
      float: left
      &:last-child
        border-right: 0
    #imgUm
      background-image: url("../assets/images/destaques/imageUm.jpg")
    #imgDois
      background-image: url("../assets/images/destaques/imageDois.jpg")
    #imgTres
      background-image: url("../assets/images/destaques/imageTres.jpg")
    #imgQuatro
      background-image: url("../assets/images/destaques/imageSete.jpg")
    #imgCinco
      background-image: url("../assets/images/destaques/imageQuatro.jpg")
    #imgSeis
      background-image: url("../assets/images/destaques/imageOito.jpg")
    #imgSete
      background-image: url("../assets/images/destaques/imageSeis.jpg")
    #imgOito
      background-image: url("../assets/images/destaques/imageCinco.jpg")
  @media all and (max-width:767px)
    .imagensDestaques
      .blocoImagem
        width: 25%
        background-size: auto 100%
        background-position: 50%
</style>
