<template>
  <div id="banner">
    <img class="ocultaMobile" alt="" src="../assets/images/banner/tres.png" />
    <img class="exibeMobile" alt="" src="../assets/images/banner/tresMobile.png" />
    <div id="englobaBanners">
      <div class="bannerFato" id="quatro">
        <div class="descricaoBanner" id="segundo">
          <p>Soluções ideais<br />para seu projeto</p>
        </div>
        <div class="comentarioBanner" id="segundo">
          <p>
            Análise técnica detalhada para dimensionamento e elaboração de propostas em
            soluções comerciais e industriais.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'banners-components',
};
</script>

<style lang="sass" scoped>
  @import "../assets/sass/main.sass"
  #banner
    width: 100%
    height: auto
    margin-bottom: 30px
    position: relative
    overflow: hidden
    img
      width: 100%
      opacity: 0
      vertical-align: top
    .controle
      width: 50px
      height: 50px
      position: absolute
      bottom: 40px
      right: 100px
      cursor: pointer
      background-color: $corVerde
      z-index: 100
      &:before
        content: ''
        width: 30px
        height: 3px
        background-color: $corAzul
        position: absolute
        top: 14px
        left: 12px
        transform: rotate(45deg)
      &:after
        content: ''
        width: 30px
        height: 3px
        background-color: $corAzul
        position: absolute
        bottom: 13px
        left: 12px
        transform: rotate(-45deg)
      &:hover
        opacity: .8
    #englobaBanners
      width: 400%
      height: 100%
      position: absolute
      transition: left .8s
      left: 0
      top: 0
      .bannerFato
        width: 25%
        height: auto
        position: relative
        float: left
        .descricaoBanner
          position: absolute
          top: 40px
          left: 50px
          p
            font-size: 2.3em
            font-weight: 700
            text-align: center
        .comentarioBanner
          width: 450px
          height: 100px
          background-color: $corAzul
          padding: 0 20px
          position: absolute
          bottom: 25px
          left: 0
          box-shadow: 4px 4px 10px 0 rgba(27, 62, 99, 0.75)
          p
            position: relative
            top: 50%
            transform: translateY(-50%)
            color: #fff
            font-size: .8em
            text-align: center
      #um,#dois,#tres,#quatro
        height: 100%
        background-size: 100%
      #um
        background-image: url("../assets/images/banner/um.png")
        .descricaoBanner
          color: #cfd84a
          text-shadow: 2px 2px rgba(125, 125, 125, 0.7)
      #dois
        background-image: url("../assets/images/banner/dois.png")
        .descricaoBanner
          color: #fff
          text-shadow: 2px 2px rgba(125, 125, 125, 0.7)
      #tres
        background-image: url("../assets/images/banner/tres.png")
        .descricaoBanner
          color: #252c7b
          text-shadow: 2px 2px rgba(125, 125, 125, 0.7)
      #quatro
        background-image: url("../assets/images/banner/quatro.png")
        .descricaoBanner
          color: #14434e
          text-shadow: 2px 2px rgba(125, 125, 125, 0.7)
    #left
      bottom: 30px
      right: 160px
      transform: rotate(180deg)
      box-shadow: 4px -4px 10px 0 rgba(50, 50, 50, 0.75)
    #right
      box-shadow: 4px 4px 10px 0 rgba(50, 50, 50, 0.75)
  @media all and (max-width:767px)
    #banner
      margin: 0
      #englobaBanners
        #um, #dois, #tres, #quatro
          width: 25%
        #um
          background-image: url("../assets/images/banner/umMobile.png")
        #dois
          background-image: url("../assets/images/banner/doisMobile.png")
        #tres
          background-image: url("../assets/images/banner/tresMobile.png")
        #quatro
          background-image: url("../assets/images/banner/quatroMobile.png")
        .bannerFato
          .comentarioBanner
            display: none
          .descricaoBanner
            width: 70%
            text-align: center
            font-size: .5em
            top: initial
            bottom: 13%
            left: 0
            padding: 0
      .controle
        width: 30px
        height: 30px
        &:after, &:before
          width: 15px
          height: 2px
          left: 8px
          bottom: 9px
        &:after
          bottom: 9px
        &:before
          top: 9px
      #left
        right: 70px
      #right
        right: 30px

</style>
