<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  mounted() {

  },
  methods: {

  },
};
</script>
