<template>
  <div id="solucoes">
    <h2>Soluções</h2>
    <div class="englobaBlocos">
      <div
        v-for="(item, index) in solutions"
        :class="'solutions-global-line ' + item.class"
        :key="index"
      >
        <div class="solutions-title-global">
          <img
            alt=""
            class="solutions-icon"
            :src="getImage(item.icon)"
          >
          <h3 class="solutions-title">{{item.title}}</h3>
        </div>
        <p class="solutions-line-description">
          {{item.description}}
          <span v-if="item.landingPage">
            <br />
            <div class="solutions-title-global">
              <h3 class="solutions-title academy">Portal do Aluno</h3>
            </div>
            <a :href="item.landingPage.link" target="_blank" rel="noopener noreferrer">
              <b>Clique aqui</b>
            </a>  e acesse o treinamento on line.
            <br />
            O acesso ao curso expira, então inicie e finalize o quanto antes.
            <br />
            <br />
            Quer atualizar seus conhecimentos?
            <br />
            Conheça o portal de treinamentos, a plataforma funciona como uma Faculdade
            Online, onde além de videoaulas você terá provas e atividade a fazer.
            <br />
            Envie WhatsApp, faça seu cadastro e começa o quanto antes!
            <br />
            As vagas são limitadas ao mês para que tenhamos uma rotatividade de técnicos
            adquirindo conhecimento.
          </span>
        </p>
        <div
          v-for="(asd, index) in item.itens"
          :class="'global-block ' + asd.class"
          :key="index"
        >
          <div :class="'blocoSolucoes ' + asd.class">
            <h4>{{asd.title}}</h4>
            <div class="engloba-content">
              <div class="global-image">
                <img
                  :src="getImage(asd.image)"
                  class="solutions-image"
                  :alt="`Imagem ilustrativa ${asd.title}`"
                >
              </div>
              <div class="solutions-descriptions">
                <p>{{asd.description}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <represented />
  </div>
</template>

<script>
import represented from './represented-components.vue';

export default {
  name: 'solutions-components',
  components: {
    represented,
  },
  data() {
    return {
      solutions: [
        {
          class: 'aquecimento',
          title: 'Aquecimento de Água',
          description: 'Com soluções completas para aquecimento de água para banho e piscina em residências, comércios e também para processos industriais, garantimos muito mais que água quente, mas muito conforto e redução nos gastos com aquecimento de água, além da garantia da marca especialista em aquecimento.',
          icon: 'fire.svg',
          itens: [
            {
              image: 'aquecimentoSolarBanho.png',
              class: 'solar-banho',
              title: 'Aquecimento Solar - Banho',
              description: 'O aquecedor solar para chuveiro é uma das escolhas mais efetivas para o aquecimento de água. Esse sistema utiliza uma fonte energética inesgotável, gratuita e abundante, que é o sol. A Heliotek oferece sistemas completos de aquecimento solar.',
            },
            {
              image: 'aquecimentoPiscinaSolar.png',
              class: 'solar-piscina',
              title: 'Aquecimento Solar - Piscina',
              description: 'O aquecimento solar para piscina é eficiente para manter a água quente, atendendo às diversas demandas e portes de piscinas, sejam elas residenciais e comerciais, e possibilita que a água da piscina chegue até 8ºC acima da temperatura ambiente.',
            },
            {
              image: 'novaBombaCalor.webp',
              class: 'bomba-calor',
              title: 'Aquecimento por eficiência energética - Bomba de calor',
              description: 'Para garantir conforto térmico e água aquecida durante o ano todo em qualquer condição climática, a bomba de calor é a solução ideal, seja para manter a água de sua piscina em temperatura ideal como para aquecimento central em estabelecimentos.',
            },
            {
              image: 'aquecimentoGasNovo.png',
              class: 'aquecimento-gas',
              title: 'Aquecimento de Água - Gás',
              description: 'Com modelos mecânicos e eletrônicos, que se adaptam à diversas necessidades, a Bosch, oferece aquecedores a gás que se destacam pela alta performance e durabilidade, proporcionando uma expressiva economia de energia.',
            },
            {
              image: 'geradora.png',
              class: 'geradora',
              title: 'Geradora de Água Quente',
              description: 'Equipamentos projetados para empreendimentos comerciais e industriais exigentes e com grande demanda de água quente, adaptando-se a necessidade de cada projeto, visando a eficiência energética máxima.',
            },
            {
              image: 'caldeira.jpg',
              class: 'caldeira',
              title: 'Caldeira à Vapor',
              description: 'Sistemas perfeitamente customizadas e altamente eficientes para aplicações comerciais e industriais — seja em termos de caldeiras industriais, usinas de produção combinada de eletricidade e calor e sistemas de recuperação de calor residual.',
            },
          ],
          landingPage: {
            id: 1,
            title: 'Aquecimento de Água',
            text: 'Conheça o portal de treinamentos on line.',
            link: 'https://heliotek.sistematutor.com.br/portal/login',
            logo: 'https://aquecimentodeagua.com.br/dist/brandAquecimentoDeAgua.svg',
            display: 0,
          },
        },
        {
          class: 'climatizacao',
          title: 'Climatização',
          description: 'Atuamos também com a Gree Commercial Air Conditioners, Actua Controls e a KAO Dutos com uma linha completa na área de climatização.',
          icon: 'cold.svg',
          itens: [
            {
              image: 'climatizacaoComercialMulti.jpg',
              class: 'climatizadorMulti',
              title: 'Climatização Comercial - Mult Split Inverter',
              description: 'Estes equipamentos foram desenvolvidos para locais com pouco espaço de instalação, o que os torna ideais para apartamentos residenciais, estabelecimentos comerciais e escritórios em geral.',
            },
            {
              image: 'valvula.png',
              class: 'controladores',
              title: 'Sistemas de Controle para Ar Condicionado',
              description: 'Atuamos com uma empresa especialista na importação e comercialização de sistemas de controle para ar condicionado.',
            },
            {
              image: 'climatizacaoComercial.jpg',
              class: 'climatizador',
              title: 'Climatização Comercial - VRF',
              description: 'VRF é um dos sistemas mais avançados de ar condicionado. Neste sistema as unidades são capazes de detectar a demanda exata de calor e ajustar sua operação eliminando o desperdício de energia.',
            },
            {
              image: 'dutos.png',
              class: 'acessorios',
              title: 'Dutos Rígidos e Flexíveis',
              description: 'Linha de tubos flexíveis e rígidos específicos para diversas aplicações e projetos de ar condicionado e climatização. Projetados para resistir a condições intensas e para condução do ar.',
            },
          ],
        },
        {
          class: 'renovacao',
          title: 'Renovação de Ar',
          description: 'Além disso, atuamos com a Comparco e Ventokit com uma linha completa na área renovação de ar com exaustores, grades de ventilação, grelhas e difusores.',
          icon: 'air.svg',
          itens: [
            {
              image: 'gradesVentilacao.png',
              class: 'grades-ventilacao',
              title: 'Grades de Ventilação',
              description: 'Para renovação natural dos ambientes. Instalação fácil, podendo ser de encaixe ou superfície. Modelos e tamanhos variados. Opcionais: tela anti-mosquito, cores, colarinho para encaixe, adesivas e etc.',
            },
            {
              image: 'renovadorAr.jpg',
              class: 'renovador-ar',
              title: 'Renovação de Ar',
              description: 'Líder no mercado de renovação e exaustão mecânica do ar, para ambientes com pouca ou nenhuma ventilação. Diferentes sistemas de acionamento, capacidade, design e potência.',
            },
            {
              image: 'grelhas01.jpg',
              class: 'controladores',
              title: 'Difusores',
              description: 'Linha de difusores que atende as exigências técnicas estritas no desempenho da função de ventilação, retorno ou insuflamento.',
            },
            {
              image: 'acessorios2.jpg',
              class: 'acessorios',
              title: 'Acessórios',
              description: 'Solução simples e completa para a instalação produtos da linha de aquecimento de água e também de climatização / renovação de ar.',
            },
          ],
        },
      ],
    };
  },
  methods: {
    getImage(image) {
      /* eslint-disable */
        return require(`../assets/images/solucoes/${image}`);
      /* eslint-enable */
    },
  },
};
</script>

<style lang="sass" scoped>
  @import '../assets/sass/_variables.sass'
  #solucoes
    width: 1280px
    margin: 0 auto
    padding: 0 15px
    h2
      font-size: 36px
      width: 100%
      font-weight: 700
      text-align: center
      color: $corAzul
      font-weight: 700
      margin-bottom: 12px
    .englobaBlocos
      overflow: hidden
      .solutions-global-line
        width: 100%
        overflow: hidden
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        .solutions-title-global
          width: 100%
          display: flex
          justify-content: left
          align-items: flex-end
          .solutions-icon
            width: 32px
            fill: $corAzul
          .solutions-title
            margin-left: 12px
            color: $corAzul
            margin-bottom: 0
        .solutions-line-description
          text-align: justify
          margin: 16px 0 32px
          span a
            color: #000
        .global-block
          width: 49%
          overflow: hidden
          .blocoSolucoes
            width: 100%
            margin: 0 0 32px
            position: relative
            float: left
            h4
              width: 100%
              height: 50px
              text-align: left
              background-color: $corAzul
              padding: 15px 20px
              border-bottom: 2px solid $corVerde
              color: #fff
              display: flex
              align-items: center
              font-size: 18px
              margin-bottom: 12px
            .engloba-content
              display: flex
              align-items: center
              padding: 0 16px
              .global-image
                width: 120px
                margin-right: 16px
                display: flex
                justify-content: center
                align-items: center
                .solutions-image
                  max-width: 120px
                  max-height: 100px
              .solutions-descriptions
                flex: 1
                text-align: justify
            .comandosOpcionais
              width: 100%
              height: 50px
              position: absolute
              bottom: 0
              left: 0
              border-top: 2px solid $corVerde
              margin: 0 auto
              a
                width: 50%
                height: 100%
                text-align: center
                line-height: 50px
                background-color: $corAzul
                color: #fff
                float: left
              .blocoTec
                border-left: 1px solid $corVerde
              .blocoGaleria
                border-right: 1px solid $corVerde
        .publish-outside
          width: 100%
          display: flex
          margin: 12px 0 80px
          img
            width: 200px
            margin-left: 40px
          p
            flex: 1
            text-align: center
        &.aquecimento
          .global-block .blocoSolucoes h4
            background-color: $corVerde
            border-color: $corAzul
          .solutions-title-global
            .solutions-icon, .solutions-title
              fill: $corVerde
              color: $corVerde
              &.academy
                font-size: 20px
                margin: 16px 0 8px
  @media all and (min-width:$larguraSecundaria) and (max-width:$larguraPrimaria)
    #solucoes
      width: $larguraSecundaria
  @media all and (max-width:767px)
    #solucoes
      width: 100%
      .englobaBlocos
        .solutions-global-line
          .solutions-title-global
            align-items: center
            margin-top: 16px
            .solutions-title
              text-align: left
          .global-block
            width: 100%
            .blocoSolucoes
              width: 100%
              h4
                font-size: 1em
              .engloba-content
                flex-wrap: wrap
                justify-content: center
                .global-image
                  width: 100%
                  height: 80px
                  margin: 16px 0
                  .solutions-image
                    max-width: initial
                    height: 100px
                .solutions-descriptions
                  flex: initial
                  width: 100%
            &.climatizadorMulti
              order: 1
            &.climatizador
              order: 2
            &.controladores
              order: 3
            &.renovador-ar
              order: 4
            &.grades-ventilacao
              order: 5
            &.acessorios
              order: 6
          .publish-outside
            flex-wrap: wrap
            text-align: justify
            img
              order: 2
              width: 100%
              padding: 20px 40px 0
              margin: 0
</style>
