import Vue from 'vue';
import VueRouter from 'vue-router';

const lazy = (name) => () => Promise.resolve(Vue.component(name));

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: '/',
    },
    {
      path: '/',
      component: lazy('home-page'),
      name: 'index',
      props: true,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
