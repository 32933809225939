<template>
  <header>
    <div id="englobaHeader">
      <div id="logoMenu" />
      <div id="menu">
        <ul id="itensMenu">
          <li id="home">Home</li>
          <li id="empresa">Quem Somos</li>
          <li id="produtos">Soluções</li>
          <li id="contato">Contato </li>
          <li class="exibeMobile">(31) 3043-5999</li>
          <li class="exibeMobile">representante@diatech.com.br</li>
        </ul>
        <div class="ativoMobile">
          <div id="quadrado">
            <div id="barras" />
          </div>
        </div>
      </div>
      <img id="iconeTell" src="../assets/images/telefone.svg" alt="" />
      <div id="telefoneDesktop">
        <p>(31) 3396-3453</p>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'header-site',
  components: {

  },
  beforeMount() {

  },

  data() {
    return {

    };
  },

  methods: {

  },
};
</script>

<style lang="sass" scoped>
  @import '../assets/sass/_variables.sass'
  header
    width: 100%
    height: 120px
    background-color: #f7f7f7
    padding: 0 15px
    position: fixed
    top: 0
    left: 0
    z-index: 1000
    &:before
      content: ''
      width: 100%
      height: 2px
      position: absolute
      bottom: 0
      left: 0
      background-color: $corVerde
      z-index: 10000
    #englobaHeader
      width: $larguraPrimaria
      height: 63px
      margin: 0 auto
      position: relative
      top: 50%
      transform: translateY(-50%)
      #logoMenu
        width: 300px
        height: 63px
        background-image: url("../assets/images/logoDiatech.svg")
        float: left
      #menu
        position: absolute
        bottom: 0
        right: 0
        ul
          width: 600px
          li
            width: 25%
            cursor: pointer
            float: left
            text-align: center
        .ativoMobile
          display: none
      #iconeTell
        width: 23px
        position: absolute
        top: 0
        right: 172px
      #telefoneDesktop
        position: absolute
        top: 0
        right: 42px
  @media all and (min-width:$larguraSecundaria) and (max-width:$larguraPrimaria)
    header
      width: 100%
      #englobaHeader
        width: $larguraSecundaria
        padding: 0
  @media all and (max-width:767px)
    header
      height: 68px
      padding: 0
      &:before
        display: none
      #englobaHeader
        width: 100%
        height: 68px
        top: initial
        transform: initial
        #logoMenu
          width: 100%
          height: 100%
          margin: 0 auto
          z-index: 1000
          background-color: #f7f7f7
          background-size: auto 62%
          background-position: 40% 12px
          position: absolute
          top: 0
          left: 0
        #menu
          .ativoMobile
            display: block
          #quadrado
            width: 35px
            height: 31px
            border: 2px solid #7fa72e
            display: block
            position: fixed
            top: 21px
            right: 10px
            z-index: 1000
            #barras
              width: 25px
              height: 3px
              background-color: #7fa72e
              position: absolute
              top: 12px
              right: 3px
              z-index: 1000
              &:before
                content: ''
                width: 25px
                height: 3px
                background-color: #7fa72e
                top: -6px
                right: 0
                position: absolute
                z-index: 1000
              &:after
                content: ''
                width: 25px
                height: 3px
                background-color: #7fa72e
                top: 6px
                right: 0
                position: absolute
                z-index: 1000
          ul
            width: 100%
            height: 300px
            position: fixed
            top: -230px
            right: 0
            background-color: #f7f7f7
            display: block
            transition: top .8s
            z-index: 10
            &:before
              content: ''
              width: 100%
              height: 2px
              position: absolute
              top: 0
              left: 0
              background-color: $corVerde
              z-index: 10000
            &:after
              content: ''
              width: 100%
              height: 2px
              position: absolute
              bottom: 0
              left: 0
              background-color: $corVerde
              z-index: 10000
            li
              width: 100%
              height: 50px
              line-height: 50px
              float: none
              text-align: left
              padding: 0 15px
              border-bottom: 1px solid #000
              &.exibeMobile
                height: 50px
                border-bottom: none
                text-align: center
        #telefoneDesktop, #iconeTell
          display: none
</style>
