import { render, staticRenderFns } from "./represented-components.vue?vue&type=template&id=b2ab61d4&scoped=true&"
import script from "./represented-components.vue?vue&type=script&lang=js&"
export * from "./represented-components.vue?vue&type=script&lang=js&"
import style0 from "./represented-components.vue?vue&type=style&index=0&id=b2ab61d4&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2ab61d4",
  null
  
)

export default component.exports