<template>
  <div class="englobaRepresentadas">
    <div v-for="(item, index) in represented" :key="index" class="representada">
      <div v-if="item.link" class="logoRepresented">
        <a :href="item.link" target="_blank" rel="noopener noreferrer" is="TextWrapper">
          <img
            :src="getImage(item.image)"
            alt=""
            target="_blank"
          />
        </a>
      </div>
      <div v-else class="logoRepresented">
        <img
          class="logoRepresented"
          :src="getImage(item.image)"
          alt=""
          target="_blank"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'represented-components',
  components: {

  },
  beforeMount() {

  },

  data() {
    return {
      represented: [
        {
          name: 'bosch',
          image: 'bosch.svg',
          link: 'https://www.bosch-thermotechnology.com/br/pt/home/',
        },
        {
          name: 'heliotek',
          image: 'heliotek.svg',
          link: 'http://www.heliotek.com.br/',
        },
        {
          name: 'ventokit',
          image: 'ventokit.svg',
          link: '',
        },
        {
          name: 'actuaControls',
          image: 'actua.svg',
          link: 'http://www.actuacontrols.com.br/',
        },
        {
          name: 'comparco',
          image: 'comparco.svg',
          link: '',
        },
        {
          name: 'kaoDutos',
          image: 'kaoDutos.svg',
          link: 'http://www.kaodutos.com.br/',
        },
        {
          name: 'mitsubishi',
          image: 'mitsubishi.svg',
          link: '',
        },
        {
          name: 'gree',
          image: 'gree.svg',
          link: '',
        },
      ],
    };
  },
  methods: {
    getImage(image) {
      /* eslint-disable */
        return require(`../assets/images/representada/${image}`);
      /* eslint-enable */
    },
  },
};
</script>

<style lang="sass" scoped>
  @import '../assets/sass/_variables.sass'
  .englobaRepresentadas
    margin-bottom: 60px
    overflow: hidden
    display: flex
    flex-wrap: wrap
    .representada
      width: 25%
      height: 70px
      margin: 10px 0
      background-size: 70%
      filter: grayscale(100%)
      display: flex
      align-items: center
      justify-content: center
      &:hover
        filter: grayscale(0%)
      .logoRepresented
        width: 70%
        display: flex
        justify-content: center
        img
          max-width: 100%
          max-height: 50px
  @media all and (max-width:767px)
    .englobaRepresentadas
      .representada
        width: 50%
      #bosch
        background-size: auto 50%
      #heliotek
        background-size: auto 60%
      #wdb
        background-size: auto 50%
      #ventokit
        background-size: auto 50%
      #comparco
        background-size: auto 30%
      #kaoDutos
        background-size: auto 70%
      #actuaControls
        background-size: auto 60%
      #gree
        background-size: auto 50%
</style>
