<template>
  <footer id="footer">
    <div class="global-footer-content">
      <div class="footer-logo-description">
        <img :src="getImage('logoDiatechUm.svg')" class="footer-logo-diatech" alt="">
        <p class="footer-logo-text">
          Diatech Representação & Consultoria<br>
          Belo Horizonte - Minas Gerais
        </p>
      </div>
      <div class="footer-contact">
        <h3 class="footer-title">Contato</h3>
        <div class="footer-contact-general">
          <p class="footer-contact-general-text">
            <span>Telefone:</span>
            (31) 3396-3453
          </p>
          <p class="footer-contact-general-text">
            <span>E-mail:</span>
            <a href="mailto:comercial@diatech.com.br">
              comercial@diatech.com.br
            </a>
          </p>
        </div>
        <div class="footer-contact-social">
          <a
            v-for="(item, index) in social"
            :href="item.link"
            target="_blank"
            class="footer-contact-social-link"
            rel="noopener noreferrer"
            :key="index"
          >
            <img :src="getImage(item.image)" :alt="`Link para ${item.name}`">
          </a>
        </div>
      </div>
      <div class="footer-seo">
        <h3 class="footer-title">Keywords</h3>
        <p class="footer-seo-keywords">
          Aquecedor Belo Horizonte, Aquecedor BH, Aquecimento solar Belo Horizonte,
          Aquecimento Solar BH, Aquecedor de Piscina, Aquecedor de Piscina Belo Horizonte,
          Aquecedor de Piscina BH, Aquecedor Solar Piscina, Aquecedor Gás Belo Horizonte,
          Aquecedor Gás BH, Bomba de Calor, Bomba de Calor Belo Horizonte, Bomba de Calor
          BH, Trocador de Calor, Trocador de Calor Belo Horizonte, Trocador de Calor BH,
          VRF Belo Horizonte, VRF BH, Ventokit, Ventokit Belo Horizonte, Ventokit BH, Ar
          Condicionado Belo Horizonte, Ar Condicionado BH, Bosch Belo Horizonte, Bosch BH,
          Aquecedor Bosch, Aquecedor Heliotek, WdB, Gree, Comparco, Actua Controls, KAO
          Dutos.
        </p>
      </div>
    </div>
    <div class="wrapper">
      <div class="copyright">
        <p>
          © {{ new Date().getFullYear() }} - Diatech Representação & Consultoria
          - Todos os direitos reservados
        </p>
      </div>
      <div class="click-gestao">
        <a href="https://clickgestao.com.br" target="_blank" rel="noopener noreferrer">
          <p>Desenvolvido por</p>
          <img src="https://clickgestao.com.br/img/brand-click-gestao-white.svg" alt="" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footer-site',
  data() {
    return {
      social: [
        {
          name: 'facebook',
          link: 'https://www.facebook.com/diatech.com.br',
          image: 'facebook.svg',
        },
        {
          name: 'whatsapp',
          link: 'https://api.whatsapp.com/send?phone=553130435999',
          image: 'whatsapp.svg',
        },
      ],
    };
  },

  methods: {
    getImage(image) {
      /* eslint-disable */
        return require(`../assets/images/${image}`);
      /* eslint-enable */
    },
  },
};
</script>

<style lang="sass" scoped>
  @import '../assets/sass/_variables.sass'
  footer
    width: 100%
    padding: 28px
    background-color: $corAzul
    position: relative
    color: #FFF
    a
      color: #FFF
    &:before
      content: ''
      width: 100%
      height: 3px
      position: absolute
      bottom: 0
      left: 0
      background-color: $corVerde
    .global-footer-content
      width: 1280px
      margin: 0 auto 40px
      display: flex
      .footer-contact, .footer-seo
        .footer-title
          color: #FFF
          margin-bottom: 12px
          text-align: left
          font-size: 20px
          text-decoration: underline
          font-style: italic
      .footer-logo-description
        flex: 1
        .footer-logo-diatech
          width: 180px
          opacity: .5
          margin-bottom: 24px
      .footer-contact
        flex: 1
        .footer-contact-general
          .footer-contact-general-text
            span
              font-weight: 700
        .footer-contact-social
          margin-top: 8px
          .footer-contact-social-link
            img
              width: 28px
            &:last-child img
              margin-left: 16px
      .footer-seo
        flex: 1
        .footer-seo-keywords
          font-size: 12px
          text-align: justify
    .wrapper
      width: 100%
      display: flex
      align-items: flex-end
      margin-bottom: 40px
      .copyright
        width: calc(100% - 140px)
        p
          width: 100%
          font-weight: 300
          font-size: 15px
          font-style: italic
          color: #FFF
          text-align: center
          padding-left: 140px
      .click-gestao
        width: 140px
        display: flex
        flex-wrap: wrap
        justify-content: flex-end
        p
          width: 100%
          color: #FFF
          text-align: center
          font-weight: 300
          text-transform: lowercase
          font-size: 10px
          margin-bottom: 4px
        img
          width: 100%
        @media (max-width:767px)
          order: 0
          width: 100%
          p
            text-align: right
          img
            width: 140px

  @media all and (min-width:$larguraSecundaria) and (max-width:$larguraPrimaria)
    footer
      .global-footer-content
        width: $larguraSecundaria
  @media all and (max-width:767px)
    footer
      height: auto
      padding: 20px
      .global-footer-content
        width: 100%
        flex-wrap: wrap
        margin-bottom: 8px
        .footer-logo-description, .footer-contact, .footer-seo
          width: 100%
          display: flex
          flex-wrap: wrap
          align-items: flex-start
          align-self: baseline
          flex: initial
          margin: 20px 0
      .wrapper
        flex-wrap: wrap
        margin-bottom: 24px
        .copyright
          width: 100%
          margin-bottom: 20px
          p
            padding-left: 0
</style>
