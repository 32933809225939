<template>
  <div id="publish">
    <center>
      <p class="text-publish">Publicidade</p>
      <a href="https://represente.com.br" target="_blank" rel="noopener noreferrer">
        <img
          src="https://represente.com.br/img/mail/publicidade_site_desktop.png"
          alt="represente | sistema de gerenciamento de clientes"
          class="banner-publish desktop"
        />
        <img
          src="https://represente.com.br/img/mail/publicidade_site_mobile.png"
          class="banner-publish mobile"
          alt="represente | sistema de gerenciamento de clientes"
        />
      </a>
    </center>
  </div>
</template>

<script>
export default {
  name: 'publish-components',

};
</script>

<style lang="sass">
  @import "../assets/sass/main.sass"
  #publish
    width: 1280px
    margin: 32px 0
    .text-publish
      text-transform: uppercase
      font-size: 10px
      font-weight: 300
      margin-bottom: 8px
      letter-spacing: 2px
    .banner-publish
      width: 728px
      &.mobile
        display: none
  @media all and (min-width:$larguraSecundaria) and (max-width:$larguraPrimaria)
    #publish
      width: $larguraSecundaria
      padding: 0 15px
      .banner-publish
        width: 100%
        &.mobile
          display: block
        &.desktop
          display: none
  @media all and (max-width:767px)
    #publish
      width: 100%
      padding: 0 15px
      .banner-publish
        width: 100%
        &.mobile
          display: block
        &.desktop
          display: none
</style>
